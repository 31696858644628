

import __layout_0 from '/builds/tnt-holding/tnt-cms/src/layouts/default.vue'
export const layouts = {
'auth': () => import('/builds/tnt-holding/tnt-cms/src/layouts/auth.vue'),
'blank': () => import('/builds/tnt-holding/tnt-cms/src/layouts/blank.vue'),
'default': __layout_0,
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
